import { useLocation } from '@reach/router';

const useIsPressLess = (): boolean => {
    const location = useLocation();
    if (location.search.includes('ispressless')) {
        return true;
    }
    return false;
};

export default useIsPressLess;
