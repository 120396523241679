import React from 'react'
import {m, LazyMotion, domAnimation, MotionValue, MotionStyle, useScroll} from 'framer-motion';

import useTransformArray from '../hooks/use-transform-array'
import { ScrollCtx } from '../context/scroll-area'
import { getPositionOfElement } from '../../../utils/get-position-of-element'
import useIsMobileDevice from "../hooks/use-is-mobile.device";

interface StaticRenderProps {
    scrollY: MotionValue
    properties?: {
        [key in keyof MotionStyle]: (string | number)[]
    }
    inViewProperties?: {
        [key in keyof MotionStyle]: (string | number)[]
    }
    children: React.ReactNode | React.ReactNode[]
    transformOrigin?: string
    inViewTransitionTime?: number
    inViewDelay?: number
    runOnce?: boolean
    isPast?: boolean
    fitIn?: boolean
}

// Inner component used to handle breaking the React rules of hooks
const StaticRender = ({
                          scrollY,
                          properties = {},
                          transformOrigin = 'center center',
                          inViewProperties = {},
                          inViewTransitionTime = 0.4,
                          inViewDelay = 0,
                          runOnce = true,
                          isPast = false,
                          children,
                          fitIn = false,
                      }: StaticRenderProps): JSX.Element => {
    const [zProps] = React.useState(properties)
    const props = useTransformArray({ properties: zProps, scrollY })
    let style = {}
    if (fitIn) {
        style = { width: '100%', height: '100%' }
    }
    return (
        <m.div
            style={{ ...props, transformOrigin, ...style }}
            initial={
                !isPast || !runOnce
                    ? {
                        ...Object.fromEntries(
                            Object.entries(inViewProperties).map(([k, v]) => [
                                k,
                                v[0],
                            ])
                        ),
                    }
                    : {
                        ...Object.fromEntries(
                            Object.entries(inViewProperties).map(([k, v]) => [
                                k,
                                v[1],
                            ])
                        ),
                    }
            }
            whileInView={
                !isPast || !runOnce
                    ? {
                        ...Object.fromEntries(
                            Object.entries(inViewProperties).map(([k, v]) => [
                                k,
                                v[1],
                            ])
                        ),
                        transition: {
                            duration: inViewTransitionTime,
                            delay: inViewDelay,
                        },
                    }
                    : {}
            }
            viewport={{
                once: runOnce,
            }}
        >
            {children}
        </m.div>
    )
}

type ScrollEffectProps = Omit<StaticRenderProps, 'scrollY' | 'isPast'> & {
    internalScroller?: boolean
} & HtmlPropsNoRef<HTMLDivElement>

// Main component used to handle the scrolling effect
const ScrollEffectComponent = ({
                          transformOrigin,
                          properties = {},
                          inViewProperties = {},
                          inViewTransitionTime = 0.4,
                          inViewDelay = 0,
                          children,
                          internalScroller = false,
                          runOnce = true,
                          fitIn = false,
                          ...props
                      }: ScrollEffectProps): JSX.Element => {
    const ctx = React.useContext(ScrollCtx)
    const isMobile = useIsMobileDevice()

    const ref = React.useRef(null)
    const { scrollYProgress: yProgress } = useScroll({
        target: ref,
        offset: ['start end', 'end -100vh'],
    })

    const [past, setPast] = React.useState(false)
    React.useEffect(() => {
        const pos = getPositionOfElement(ref)
        if (pos && pos?.top < 0) {
            setPast(true)
        }
    }, [ref])

    return (
        <div ref={ref} {...props}>
            {!isMobile && (
                <StaticRender
                    key={JSON.stringify({ properties, past })}
                    scrollY={
                        (ctx?.progress && internalScroller) === false
                            ? ctx?.progress : yProgress
                    }
                    transformOrigin={transformOrigin}
                    properties={properties}
                    inViewProperties={inViewProperties}
                    inViewTransitionTime={inViewTransitionTime}
                    inViewDelay={inViewDelay}
                    runOnce={runOnce}
                    isPast={past}
                    fitIn={fitIn}
                >
                    {children}
                </StaticRender>
            )}
            {isMobile && children}
        </div>
    )
}


const ScrollEffect = ({ children, ...props }: ScrollEffectProps): JSX.Element => {
    return (
        <LazyMotion features={domAnimation}>
            <ScrollEffectComponent {...props}>{children}</ScrollEffectComponent>
        </LazyMotion>
    )
}
export default ScrollEffect
