exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-dynamic-pages-all-wp-contact-tsx": () => import("./../../../src/templates/dynamic-pages/allWpContact.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-all-wp-contact-tsx" */),
  "component---src-templates-dynamic-pages-all-wp-einrichtung-tsx": () => import("./../../../src/templates/dynamic-pages/allWpEinrichtung.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-all-wp-einrichtung-tsx" */),
  "component---src-templates-dynamic-pages-all-wp-event-tsx": () => import("./../../../src/templates/dynamic-pages/allWpEvent.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-all-wp-event-tsx" */),
  "component---src-templates-dynamic-pages-all-wp-post-tsx": () => import("./../../../src/templates/dynamic-pages/allWpPost.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-all-wp-post-tsx" */),
  "component---src-templates-dynamic-pages-anreise-und-lageplan-tsx": () => import("./../../../src/templates/dynamic-pages/anreise-und-lageplan.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-anreise-und-lageplan-tsx" */),
  "component---src-templates-dynamic-pages-arztliche-niederlassungen-tsx": () => import("./../../../src/templates/dynamic-pages/arztliche-niederlassungen.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-arztliche-niederlassungen-tsx" */),
  "component---src-templates-dynamic-pages-einrichtungen-nach-schwerpunkt-tsx": () => import("./../../../src/templates/dynamic-pages/einrichtungen-nach-schwerpunkt.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-einrichtungen-nach-schwerpunkt-tsx" */),
  "component---src-templates-dynamic-pages-kliniken-tsx": () => import("./../../../src/templates/dynamic-pages/kliniken.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-kliniken-tsx" */),
  "component---src-templates-dynamic-pages-mvz-tsx": () => import("./../../../src/templates/dynamic-pages/mvz.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-mvz-tsx" */),
  "component---src-templates-dynamic-pages-notfall-tsx": () => import("./../../../src/templates/dynamic-pages/notfall.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-notfall-tsx" */),
  "component---src-templates-dynamic-pages-search-tsx": () => import("./../../../src/templates/dynamic-pages/search.tsx" /* webpackChunkName: "component---src-templates-dynamic-pages-search-tsx" */),
  "component---src-templates-wp-einrichtung-tsx": () => import("./../../../src/templates/WpEinrichtung.tsx" /* webpackChunkName: "component---src-templates-wp-einrichtung-tsx" */),
  "component---src-templates-wp-event-tsx": () => import("./../../../src/templates/WpEvent.tsx" /* webpackChunkName: "component---src-templates-wp-event-tsx" */),
  "component---src-templates-wp-form-tsx": () => import("./../../../src/templates/WpForm.tsx" /* webpackChunkName: "component---src-templates-wp-form-tsx" */),
  "component---src-templates-wp-page-tsx": () => import("./../../../src/templates/WpPage.tsx" /* webpackChunkName: "component---src-templates-wp-page-tsx" */),
  "component---src-templates-wp-post-tsx": () => import("./../../../src/templates/WpPost.tsx" /* webpackChunkName: "component---src-templates-wp-post-tsx" */)
}

