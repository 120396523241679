import React from "react"
const BadAccess = React.lazy(() => import("./bad-access"));
const Wrap = React.lazy(() => import("./wrap"));


export const wrapPageElement = ({ element, props }) => {
    if (props.pageContext.isPressless) {
        if (window.self !== window.top) {
            return <React.Suspense fallback={<></>}><Wrap>{element}</Wrap></React.Suspense>
        }
        return <React.Suspense fallback={<></>}><BadAccess/></React.Suspense>
    }
    return element
}
