import React from 'react';
import ColSet from '../grid/col-set';
import VisuallyHidden from '../visually-hidden';

interface BulletPointsProps {
    currentSlide: number
    length: number;
    onClick: (index: number) => void
}

const ScrollSections = ({ currentSlide, length, onClick }: BulletPointsProps) => (
    <div
        css={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        }}
    >
        <ColSet breakpoints={{ dt: { between: 0 } }} css={{ justifyContent: 'center' }}>
            {(Array.from(new Array(length)).map((_, idx) => (
                <button
                    key={idx}
                    type="button"
                    css={{
                        width: '9px',
                        height: '9px',
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'flex-start',
                        transition: '0.3s',
                        borderBottom: '1px solid var(--color-blue-dunkelblau)',
                        cursor: currentSlide === idx ? 'not-allowed' : 'pointer',
                    }}
                    disabled={currentSlide === idx}
                    style={{
                        opacity: currentSlide === idx ? 1 : 0.3,
                    }}
                    onClick={() => {
                        onClick(idx);
                    }}
                >
                    <VisuallyHidden>
                        Go to slide
                        {' '}
                        {idx}
                    </VisuallyHidden>
                </button>
            )))}
        </ColSet>
    </div>
);

export default ScrollSections;
