import './reset.css';
import './theme.css';
import './quill.css';
import React from 'react';
import { PageProps } from 'gatsby';
import { Global } from '@emotion/react';
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import Header from '../components/header';
import '@reach/skip-nav/styles.css';
import FadeTransition from '../components/animations/transitions/fade-transition';
import Provider from '../components/primitives/provider';
import generateRootStyles from '../utils/generate-root-styles';
import GridContextProvider from '../components/primitives/grid/context';
import 'focus-visible/dist/focus-visible';
import DesktopFooter from '../components/menu/desktop-footer';
import ContrastProvider from '../components/theme/contrast-context';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Layout = ({ children = {}, location, pageContext }: PageProps): React.ReactElement => (
    <ContrastProvider>
        <Provider>
            <GridContextProvider
                breakpoints={{
                    dt: { query: '@media screen', columns: 12, gap: 20 },
                    tb: { query: '@media (max-width: 1200px)', columns: 12, gap: 20 },
                    mb: { query: '@media (max-width: 767px)', columns: 4, gap: 16 },
                }}
                maxWidth={1440}
                desktopFirst
            >
                <Global
                    styles={generateRootStyles({
                        smallScreenSize: 360,
                        largeScreenSize: 1440,
                        backgroundColor: '#fff',
                        textColor: '#383F42',
                    })}
                />
                <SkipNavLink contentId="skip-nav">
                    Zum Inhalt springen
                </SkipNavLink>
                <div className="__audal_labs" css={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
                    <Header language={(pageContext as any).language} translations={(pageContext as any).translations} />
                    <FadeTransition shouldChange={location.pathname}>
                        <SkipNavContent css={{ width: '100%', display: 'flex', overflowX: 'clip', flexDirection: 'column' }} id="skip-nav" as="main">
                            {children}
                        </SkipNavContent>
                    </FadeTransition>
                    <DesktopFooter />
                </div>
            </GridContextProvider>
        </Provider>
    </ContrastProvider>
);

export default Layout;
