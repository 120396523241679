// gatsby-browser.js
const React = require('react')
const ReactDOM = require('react-dom'); // Import ReactDOM
const loadableReady = require("@loadable/component").loadableReady
const {navigate} = require('gatsby');
import './src/layouts/fonts/fonts.css';


const checkAndPreloadPageData = async (pathname) => {
    navigate(pathname);
};

export const onPreRouteUpdate = ({location}) => {
    // Add event listener for popstate (back/forward navigation)
    window.addEventListener('popstate', async (event) => {
        const pathname = window.location.pathname;
        await checkAndPreloadPageData(pathname);
    });
};

export const replaceHydrateFunction = () => {
    return (element, container, callback) => {
        loadableReady(() => {
            ReactDOM.createRoot(container, {
                hydrate: true,
                hydrationOptions: {onHydrated: callback},
            }).render(element, callback)
        })
    }
}

