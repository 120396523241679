
type resolveType = () => void;
type rejectType = (reason?: any) => void;

/**
 * Adjust this number based on the overall performance of your application.
 * Start with 5 as a safe initial guess and adjust up or down as needed.
 */
const MAX_SIMULTANEOUS_IMAGE_LOADS = 4;

let ongoingImageLoads = 0;
const pendingImageLoads: Array<string> = [];

export function loadImage(src: string): Promise<void> {
    const img = new Image();

    return new Promise((resolve: resolveType, reject: rejectType) => {
        img.onload = () => resolve();
        img.onerror = (error) => reject(error);

        ongoingImageLoads += 1;
        if (ongoingImageLoads >= MAX_SIMULTANEOUS_IMAGE_LOADS) {
            img.onload = img.onerror = () => {
                ongoingImageLoads -= 1;
                if (pendingImageLoads.length > 0) {
                    // Start next image load.
                    loadImage(pendingImageLoads.shift() || '').then(() => pendingImageLoads.shift());
                }
            };

            // Queue this image load until some of the ongoing loads finish.
            pendingImageLoads.push(src);
        } else {
            img.src = src;
        }
    });
}
