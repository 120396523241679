import React, { useRef, useState, lazy } from 'react'
import { HidingHeader } from 'hiding-header-react'
import DesktopHeader from './menu/desktop-header'
import 'hiding-header/dist/style.css'
// import MobileHeader from './menu/mobile-header'
const MobileHeader = lazy(() => import('./menu/mobile-header'))
import isMainSite from '../utils/is-main-site'

interface HeaderProps {
    language?: {
        slug: 'en' | 'de'
    }
    translations?: {
        link: string
    }[]
}

const Header = ({ language, translations }: HeaderProps): JSX.Element => {
    const [open, setOpen] = useState(true)
    const headerRef = useRef(null)
    return (
        <>
            <HidingHeader
                className={open ? 'hidingHeader open' : 'hidingHeader closed'}
                onVisibleHeightChange={val => {
                    const isOpen = val > 50
                    setOpen(isOpen)
                    document.documentElement.classList.add(
                        isOpen ? 'header-open' : 'header-closed'
                    )
                    document.documentElement.classList.remove(
                        !isOpen ? 'header-open' : 'header-closed'
                    )
                }}
                css={{ zIndex: 100 }}
            >
                <header
                    css={{
                        ...(!isMainSite()
                            ? {
                                  '@media (max-width: 992px)': {
                                      display: 'none',
                                  },
                              }
                            : {
                                  '@media (max-width: 1024px)': {
                                      display: 'none',
                                  },
                              }),
                        zIndex: '10',
                        top: 0,
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    ref={headerRef}
                >
                    <DesktopHeader
                        language={language}
                        translations={translations}
                        headerRef={headerRef}
                    />
                </header>
            </HidingHeader>
            <React.Suspense fallback={<></>}>
                <MobileHeader />
            </React.Suspense>
        </>
    )
}

export default Header
