import React from 'react';
import {domAnimation, LazyMotion, m, MotionValue, useScroll, useSpring, useTransform,} from 'framer-motion';
import usePositionOfElement from '../hooks/use-position-of-element';
import useIsTouchDevice from '../hooks/use-is-touch-device';

interface ScrollContext {
    progress: MotionValue;
}

export const ScrollCtx = React.createContext<ScrollContext>({} as any);

interface ScrollAreaProps {
    children: React.ReactNode | React.ReactNode[];
    smooth?: boolean;
    smoothMass?: number;
    // pin?: boolean;
}

const ScrollArea = ({
                        children,
                        smooth = false,
                        smoothMass = 0.05,
                    }: ScrollAreaProps): JSX.Element => {
    const ref = React.useRef(null);
    const {scrollYProgress: scrollProgress, scrollY} = useScroll({
        target: ref,
        offset: ['start start', 'end end'],
    });
    const {width, height, pageTop} = usePositionOfElement(ref);
    const isTouch = useIsTouchDevice();

    const reversePosition = useTransform(scrollY, x => -x);
    const physics = {damping: 30, mass: smoothMass, stiffness: 200};
    const sprungY = useSpring(reversePosition, physics);

    const fixedPosition = useTransform(sprungY, (v) => {
        if (-v >= 0 && -v < height) {
            return 0;
        }
        if (-v > height) {
            return v + height;
        }
        return v;
    });

    const value = React.useMemo(() => ({
        progress: scrollProgress,
    }), [scrollProgress]);

    return (
        <ScrollCtx.Provider
            value={value}
        >
            <LazyMotion features={domAnimation}>
                <m.div
                    style={
                        height && smooth && !isTouch
                            ? {
                                position: 'fixed',
                                left: 0,
                                top: pageTop,
                                width: '100%',
                                y: fixedPosition,
                            }
                            : {}
                    }
                >
                    <div ref={ref}>{children}</div>
                </m.div>
                {height && smooth && (
                    <div
                        style={{
                            paddingBottom: `${window.innerHeight}px`,
                        }}
                    />
                )}
                {height && smooth && !isTouch && (
                    <div
                        style={{
                            height: `${height}px`,
                            width: `${width}px`,
                        }}
                    />
                )}
            </LazyMotion>
        </ScrollCtx.Provider>
    );
};

export default ScrollArea;
