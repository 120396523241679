/* eslint-disable */
import React, { ImgHTMLAttributes, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {loadImage} from "./imgLoadingManager";

interface ISharpGatsbyData {
    gatsbyImageData: IGatsbyImageData;
}

export interface ISharpImage {
    childImageSharp?: ISharpGatsbyData;
    svgData?: string;
    publicURL?: string;
}

interface ISharpImageLocalFile {
    localFile: ISharpImage;
}

interface UseImageProps {
    src?: string | ISharpImageLocalFile;
    srcSet?: string;
    sizes?: string;
    ignoreFallback?: boolean;
}

export interface IWPImage extends ImgHTMLAttributes<any> {
    localFile?: ISharpImage;
    altText?: string;
}

export interface IWPImageProps extends ImgHTMLAttributes<any> {
    localFile?: ISharpImage;
    altText?: string;
}

export interface GatsbyImageSVGFallbackProps
    extends UseImageProps,
    Omit<ImgHTMLAttributes<any>, keyof UseImageProps>{}

export const WPImage = ({
    altText,
    localFile,
    ...props
}: IWPImageProps): React.ReactElement => (
    <GatsbyImageSVGFallback src={{ localFile }} alt={altText} {...props} />
);

interface IPressLessImage {
    className?: string
    alt?: string
    style?: any
    image?: PressLessImage
    objectFit?: any
    objectPosition?: any
    borderRadius?: any
    noWrapper?: boolean
}

export const PressLessImage = ({ image, alt, noWrapper = false, ...props }: IPressLessImage) => {;
    const [loaded, setLoaded] = useState(false);
    const [wploaded, setWpLoaded] = useState(false);

    if (image && typeof image === "object") {
        const { style, ...rest } = image
        if ("src" in image && image.src) {
            useEffect(() => {
                loadImage(rest.src)
                    .then(() => setLoaded(true))
                    .catch((error) => console.log('Failed to preload image: ', error));
            }, [rest.src]);
            //
            // return loaded && <img src={rest?.src || ''} {...props} alt={{alt} || ""} />

            return <img {...rest} {...props} alt={{alt} || ""} />

        } else if ("svgData" in image) {
            return (
                <span dangerouslySetInnerHTML={{ __html: image.svgData }} {...props} />
            )
        } else if ("images" in image && image.images) {

            if (noWrapper) {

                const img = image?.images?.fallback || {}
                console.log(img, props)
                return (
                    <img
                        alt={alt}
                        css={{
                            backgroundColor: image.backgroundColor
                        }}
                        {...img}
                        {...props}
                    />
                )
            }

            return (
                <div className='wp-img-wrapper'>
                {!wploaded && (
                  <div className="wp-img-loader" css={{
                    backgroundColor: image.backgroundColor
                }}>
                  </div>
                )}
                <GatsbyImage
                imgStyle={{
                  objectFit: props?.objectFit,
                  objectPosition: props?.objectPosition,
                  borderRadius: props?.borderRadius,
                }}
                alt={alt || image?.alt || ""}
                image={image}
                onLoad={() => setWpLoaded(true)}
                {...props}
              />
              </div>
            );
        } else if ("localFile" in image) {
            //@ts-ignore
            return <WPImage {...image} {...props} />
        }
    }
    return <img {...props} />
}

export const GatsbyImageSVGFallback = ({
    src,
    alt,
    ...props
}: GatsbyImageSVGFallbackProps): React.ReactElement => {
    if (typeof src === 'string') {
        return <img alt={alt || ''} src={src} {...props} />;
    } if (src?.localFile?.childImageSharp) {
        return (
            <GatsbyImage
                placeholder="tracedSVG"
                alt={alt || ''}
                image={src.localFile.childImageSharp.gatsbyImageData}
                {...props}
            />
        );
    } if (src?.localFile?.publicURL) {
        return (
            <img
                src={src.localFile.publicURL}
                alt={alt || ''}
                {...props}
            />
        );
    } if (src?.localFile === undefined) {
    // add an optional fallback image here
        return <img alt={alt || ''} {...props} />;
    }
    return <img {...props} />;
};
