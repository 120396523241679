import React from 'react';
// eslint-disable-next-line import/no-cycle
import { useCarouselContext } from './row';
import ControlHandleScrollSections from '../control-handles/scroll-sections';

const SliderScrollbar = (): JSX.Element | null => {
    const carouselContext = useCarouselContext();

    if (carouselContext) {
        const { currentSlide, slideLength, setCurrentSlide } = carouselContext;
        return (
            <ControlHandleScrollSections currentSlide={currentSlide} length={slideLength} onClick={setCurrentSlide} />
        );
    }

    return null;
};

export default SliderScrollbar;
