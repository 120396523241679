import { Global } from '@emotion/react';
import React from 'react';

interface IContrastContext {
    theme: ContrastValue,
    setTheme: (props: ContrastValue) => void
    switchTheme: () => void
}

export enum ContrastValue {
    normal = 'normal',
    contrast = 'contrast',
}

const ContrastContext = React.createContext<IContrastContext>({
    theme: ContrastValue.normal, setTheme: () => {}, switchTheme: () => {},
});

const ContrastProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    const [theme, setTheme] = React.useState<ContrastValue>(ContrastValue.normal);

    const switchTheme = (): void => {
        if (theme === ContrastValue.normal) {
            setTheme(ContrastValue.contrast);
        } else {
            setTheme(ContrastValue.normal);
        }
    };

    const themeStyles = {
        normal: `
        :root {
          --color-gray-grau: #707070;
          --color-gray-gray: #d5d5d5;
          --color-gray-white: #FFFFFF;
          --color-red-drkrot: #e60005;
          --color-gray-schwarz: #383f42;
          --color-gray-hellgrau: #efefef;
          --color-blue-dunkelblau-dark: #00284B;
          --color-blue-dunkelblau: #002d55;
          --color-blue-himmelblau: #698caf;
          --color-blue-mittelblau: #174874;
          --color-gray-mittelgrau: #d5d5d5;
          --color-gray-almostwhite: #f9f9f9;
          --color-gray-almostwhiter: #FEFEFE;
        }
        `,
        contrast: `
        :root {
          --color-gray-grau: #707070;
          --color-gray-gray: #d5d5d5;
          --color-gray-white: #FFFFFF;
          --color-red-drkrot: #e60005;
          --color-gray-schwarz: #383f42;
          --color-gray-hellgrau: #efefef;
          --color-blue-dunkelblau-dark: #00284B;
          --color-blue-dunkelblau: #002d55;
          --color-blue-himmelblau: #698caf;
          --color-blue-mittelblau: #174874;
          --color-gray-mittelgrau: #d5d5d5;
          --color-gray-almostwhite: #f9f9f9;
          --color-gray-almostwhiter: #FEFEFE;
        }
        `,
    };

    const value = React.useMemo(() => ({ theme, setTheme, switchTheme }), [theme, setTheme, switchTheme]);

    return (
        <ContrastContext.Provider value={value}>
            <Global
                styles={themeStyles}
            />
            {children}
        </ContrastContext.Provider>
    );
};

export const useContrast = (): IContrastContext => React.useContext(ContrastContext);

export default ContrastProvider;
