interface getLangAndTranslation {
    language?: {
        slug: 'en' | 'de'
    }
    translations?: {
        link: string
    }[]
}

interface Translations { lang: string, translation: string }

const getLangAndTranslation = ({ language, translations }: getLangAndTranslation): Translations => {
    const lang = language?.slug || 'de';
    const translation = (translations && translations[0]?.link) || `/${lang}`;

    return {
        lang,
        translation,
    };
};

export default getLangAndTranslation;
