import { useState, useEffect } from 'react';

const useIsMobileDevice = (): boolean => {
    const [isMobile, setMobile] = useState(typeof window !== 'undefined' ? window.innerWidth < 768 : false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const checkSize = () => setMobile(window.innerWidth < 768);
            window.addEventListener("resize", checkSize);
            return () => window.removeEventListener("resize", checkSize);
        }
    }, []);

    return isMobile;
}

export default useIsMobileDevice;
