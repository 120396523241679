import algoliasearch from 'algoliasearch/lite'
import qs from 'qs'
import React from 'react'
// import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { connectSearchBox } from 'react-instantsearch-dom'

const DEBOUNCE_TIME = 400
const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APPLICATION || '',
    process.env.GATSBY_ALGOLIA_PUBLIC_KEY || ''
)

const createURL = (state: any) => `?${qs.stringify(state)}`

const searchStateToUrl = (searchState: any) =>
    searchState ? createURL(searchState) : ''

const urlToSearchState = ({ search }: { search: string }) =>
    qs.parse(search.slice(1))

const useSearchBuilder = () => {
    const location = useLocation()

    const [searchState, setSearchState] = React.useState(
        urlToSearchState(location)
    )

    const debouncedSetStateRef = React.useRef<number | null | NodeJS.Timeout>(
        null
    )

    const onSearchStateChange = (updatedSearchState: any): void => {
        clearTimeout(debouncedSetStateRef.current as NodeJS.Timeout)

        debouncedSetStateRef.current = setTimeout(() => {
            if (typeof window !== 'undefined') {
                const url = searchStateToUrl(updatedSearchState)
                if (
                    typeof window !== `undefined` &&
                    typeof url !== `undefined`
                ) {
                    window.history.replaceState({}, '', url)
                }
            }
        }, DEBOUNCE_TIME)

        setSearchState(updatedSearchState)
    }

    React.useEffect(() => {
        setSearchState(urlToSearchState(location))
    }, [location])

    return {
        searchClient,
        searchState,
        onSearchStateChange,
        createURL,
    }
}

export default useSearchBuilder

interface SearchBoxProvided {
    refine: (...args: any[]) => any
    currentRefinement: string
    isSearchStalled: boolean
    searchTerm?: string
}

export const HiddenSearchBox: any = connectSearchBox(
    ({ searchTerm, refine }: SearchBoxProvided) => {
        const [isFirst, setFirst] = React.useState(true)
        React.useEffect(() => {
            if (!isFirst) {
                refine(searchTerm)
            } else {
                setFirst(false)
            }
        }, [searchTerm, isFirst, refine])
        return <span />
    }
)

export const useSearchTermState = (): [
    string,
    React.Dispatch<React.SetStateAction<string>>
] => {
    const location = useLocation()
    // @ts-ignore
    const [searchTerm, setSearchTerm] = React.useState<string>(
        urlToSearchState(location)?.query || ''
    )
    React.useEffect(() => {
        if (location.search) {
            // @ts-ignore
            setSearchTerm(urlToSearchState(location)?.query || '')
        }
    }, [location])

    return [searchTerm, setSearchTerm]
}
